import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'cleaning',
  formId: 'DK%20Office%20Cleaning',
  title: 'Kontorrengøring',
  subTitle: 'Vi elsker raske medarbejdere og rene kontorer!',
  icon: '/icons/cleaning.svg',
  image: '/assets/images/sunrise/cleaning_supermercat.png',
  theme: 'sunrise-cleaning',
  sections: [
    {
      sectionId: 'hero',
      label: 'Kontorrengøring',
      title: 'Vi elsker raske medarbejdere og rene kontorer!',
      description:
        'Vi får det hele til at skinne og hjælper med at holde jer sunde og raske. Vælg selv hvor ofte vore dygtige rengøringspartnere skal gøre rent, og om de skal pudse vinduer eller skille jer af med affald. De træder også til, når kontoret ind imellem trænger til den store tur.',
      image: '/assets/images/sunrise/cleaning_supermercat.png',
      icon: '/icons/cleaning.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Bakteriefri kontorer er virkelig det nye sort...',
      description: 'Det lyder cheesy men kommer fra hjertet: Vores kunders sikkerhed er vores første prioritet.',
      optionsItems: [
        {
          icon: '/assets/images/services/cleaning/service_1.svg',
          title: 'Løbende rengøring',
          description:
            'Med løbende rengøring holder vi skidtet under kontrol. Vi har noget for ethvert hygiejneniveau og i mange prisklasser.',
        },
        {
          icon: '/assets/images/services/cleaning/service_2.svg',
          title: 'Hovedrengøring',
          description:
            'Er det tid til den helt store tur? Forår eller ej, så kan der være behov for at komme i krogene.',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Hvordan virker det?',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/cleaning/journey_1.svg',
          title: 'Hvordan bor I?',
          description: 'For at lave det perfekte tilbud skal vi vide lidt om dit kontor.',
        },
        {
          icon: '/assets/images/services/cleaning/journey_2.svg',
          title: 'Vi besøger dig',
          description: 'Vi vil gerne ‘se giraffen’, så vi rammer så rigtigt som muligt.',
        },
        {
          icon: '/assets/images/services/cleaning/journey_3.svg',
          title: 'Få vores tilbud',
          description: 'Et par dage senere ligger dit tilbud klar på platformen. Vupti.',
        },
        {
          icon: '/assets/images/services/cleaning/journey_4.svg',
          title: 'Ja tak!',
          description: 'Det var det. Læn dig tilbage og nyd følelsen af rent kontor.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor skal Good Monday stå for rengøringen?',
      description: '',
      image: '/assets/images/services/cleaning/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-award-outline',
          title: 'Blåstemplede partnere',
          description: 'Vi sørger for, at kvalitetstjekke vore partnere, så ingen overraskelser.',
        },
        {
          icon: 'eva eva-message-circle-outline',
          title: 'Nem kommunikation',
          description: 'Du behøver blot adgang til vores platform for at kommunikere.',
        },
        {
          icon: 'eva eva-plus-circle-outline',
          title: 'Extra Extra Extra',
          description: 'Store fester? Fredagsbarer? Ingen ko på isen. Det kan ikke ses, efter vi har været der.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du, at man på arbejdspladsen rører ved omtrent 30 forskellige ting i minuttet? Spriiiit.',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
